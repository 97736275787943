.ajs-message {
  color: white;
  border-radius: 8px;
}

.ajs-message[type="success"] {
  background-color: var(--success);
}

.ajs-message[type="failure"] {
  background-color: var(--failure);
}
